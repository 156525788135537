<script>
/*
    Formid: get API-key from publish / API tab in Form.
*/
import Newsletter from './index.vue';
import http from '../../../services/http';

export default {
    extends: Newsletter,
    props: {
        formId: {
            required: true,
            type: String,
        },
    },
    methods: {
        onSubscribe() {
            if (!this.fields.email) { return false; }
            const data = this.getData();
            const url = `https://embed.enormail.eu/jsonp/${this.formId}.json?${data}`;
            http({
                method: 'get',
                url: 'https://europe-west1-blixem.cloudfunctions.net/corsprox',
                headers: { 'target-url': url }
            }).then((r) => this.onResponse(undefined, r)).catch((r) => this.onResponse(r, undefined));
            this.loading = true;
            return true;
        },

        onResponse(error, data) {
            this.loading = false;

            if (error) {
                this.error = error;
            }
            if (data && data.result === 'error') {
                this.error = data.msg.replace('0 - ', '');
            }

            if (this.error) {
                this.state = 'error';
                this.$emit('error', this.error);
            } else {
                this.state = 'success';
                this.fields.email = '';
                this.error = null;
                this.$emit('success');
            }
        },

        getData() {
            const data = {
                email: encodeURIComponent(this.fields.email.toLowerCase()),
                callback: 'jQuery123',
            };
            data[`b_${this.formId}`] = '';

            return this.toQueryString(data);
        },
    },
};
</script>

<style>

</style>
