<template>
    <div class="newsletter">
        <template v-if="state === 'default'">
            <slot name="default_header">
                {{ strings.title }}
            </slot>

            <form class="input-group" @submit.prevent="onSubscribe" :disabled="loading">
                <input type="email" class="-flex-stretch" :class="inputClass" v-model="fields.email" :placeholder="strings.email" required />
                <button type="submit" :class="[buttonClass, { '-loading': loading }]">
                    <slot name="buttonContent">{{ strings.subscribe }}</slot>
                </button>
            </form>
        </template>
        <template v-if="state === 'error'">
            <slot name="error" :msg="error">
                <div class="size-6 color-danger" v-html="error" />
                <button @click="onReset" type="button" class="-s -outline mt-s">{{ strings.retry }}</button>
            </slot>
        </template>
        <template v-if="state === 'success'">
            <slot name="success">
                <div class="color-success">
                    {{ strings.success }}.
                </div>
            </slot>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Newsletter',
    props: {
        buttonClass: String,
        inputClass: String,

        translations: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            loading: false,
            error: null,
            state: 'default',
            fields: {
                email: '',
            },
            defaultTranslations: {
                email: 'Emailadres',
                retry: 'Opnieuw',
                subscribe: 'Inschrijven',
                success: 'Succesvol ingeschreven',
                title: 'Blijf op de hoogte via onze nieuwsbrief:',
            },
            strings: {},
        };
    },

    methods: {
        onReset() {
            this.state = 'default';
            this.fields.email = '';
        },

        setTranslations() {
            this.strings = { ...this.defaultTranslations, ...this.translations };
        },

        toQueryString(data) {
            return Object.entries(data).reduce((acc, cur) => {
                acc.push(`${cur[0]}=${cur[1]}`);
                return acc;
            }, []).join('&');
        },
    },

    watch: {
        translations: {
            handler: 'setTranslations',
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style>

</style>
